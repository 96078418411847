import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Checkbox, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import { useDebounce } from "@src/hooks/use-debounce";
import { config } from "@src/config";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { InputSkeletonLayout } from "../../layout/input-skeleton-layout";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { useTracking } from "react-tracking";
import { trackEventPayload } from "@src/util/track-event-payload";

export const PlatformFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { trackEvent } = useTracking();
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const abaIdDebounce = useDebounce(abaIdWatch, config.DEBOUNCE_TIME.SHORT_DELAY);
  const isHasAbaId = abaIdDebounce && (typeof abaIdDebounce === "string" || typeof abaIdDebounce === "number");
  const isReady = productCodeWatch && isHasAbaId;

  const { result, isLoading } = useLoadAbExploration({
    productCode: productCodeWatch,
    modelAlias: SupportedModelAlias.USER_INFO,
    query: {
      dimensions: ["ama_app_id"],
      filters: [
        {
          member: "aba_id",
          operator: "equals",
          values: abaIdDebounce ? [abaIdDebounce.toString()] : [],
        },
      ].filter((f) => f.values && f.values.length > 0, []),
      limit: 10000,
      order: [["ama_app_id", "asc"]],
    },
    options: {
      // TODO: get form value instead
      cohort_date: "first_signal_date",
    },
    isSkip: !isReady,
  });

  const options = React.useMemo(() => {
    return result?.map((item: any) => {
      return {
        label: item["ama_app_id"]?.slice(-1) === "i" ? "IOS" : "Android",
        value: item["ama_app_id"]?.slice(-1),
        amaAppId: item["ama_app_id"],
      };
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  React.useEffect(() => {
    if (!options || !form.getFieldValue(FilterFormFieldsEnum.IS_ABA_ID_CHANGED)) return;
    const newPlatform = options?.map((o) => o.value.slice(-1));
    form.setFieldsValue({
      [FilterFormFieldsEnum.PLATFORM]: newPlatform,
      [FilterFormFieldsEnum.AMA_APP_IDS]: options?.map((o) => o.amaAppId),
    });
    trackEvent(trackEventPayload.generalInfoFieldUpdated({ name: "platform", value: newPlatform }, undefined, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (isHasAbaId) return;
    form.setFieldsValue({ [FilterFormFieldsEnum.PLATFORM]: [] });
  }, [isHasAbaId]);

  React.useEffect(() => {
    form.setFieldValue(FilterFormFieldsEnum.IS_AMA_APP_LOADING, isLoading);
  }, [form, isLoading]);

  return (
    <InputSkeletonLayout
      width={200}
      form={form}
      isLoading={isLoading}
      label="Platform"
      required={true}
      placeholder="Platform"
    >
      <Form.Item label="Platform" name={FilterFormFieldsEnum.PLATFORM} required>
        <SelectWithSpinAndCustomMaxTag
          disabled={!isReady}
          allowClear
          placeholder="Platform"
          options={options}
          onChange={(value, opt: any) => {
            form.setFieldsValue({ [FilterFormFieldsEnum.AMA_APP_IDS]: opt.map((o: any) => o.amaAppId) });
            trackEvent(trackEventPayload.generalInfoFieldUpdated({ name: "platform", value: value }));
          }}
          style={{
            width: 200,
          }}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item hidden name={FilterFormFieldsEnum.AMA_APP_IDS}>
        <Input />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.IS_AMA_APP_LOADING} valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>
    </InputSkeletonLayout>
  );
};
