import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Select, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { COHORT_DATE, COHORT_DATE_LABEL } from "@src/pages/ab-test-explore/contanst";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTracking } from "react-tracking";
import { trackEventPayload } from "@src/util/track-event-payload";

export const CohortDateFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { trackEvent } = useTracking();
  return (
    <Form.Item
      className="mx-1"
      label={
        <span>
          Cohort Date{" "}
          <Tooltip
            title={
              <div>
                Select Cohort date to define cohort:
                <ul className="list-disc ">
                  <li>Install date: for new-users test</li>
                  <li>Onboarding date: for old-users test </li>
                </ul>
              </div>
            }
          >
            <ExclamationCircleOutlined />
          </Tooltip>
        </span>
      }
      name={FilterFormFieldsEnum.COHORT_DATE}
      required
      rules={[
        {
          message: "Please select a cohort date",
          required: true,
        },
      ]}
    >
      <Select
        allowClear
        placeholder="Select Cohort Date"
        options={[
          {
            label: COHORT_DATE_LABEL.INSTALL_DATE,
            value: COHORT_DATE.INSTALL_DATE,
          },
          {
            label: COHORT_DATE_LABEL.ONBOARDING_DATE,
            value: COHORT_DATE.ONBOARDING_DATE,
          },
        ]}
        showSearch
        filterOption={(input, option) => {
          const { label, value } = option as any;
          if (!value || !label) return false;
          return (
            label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(value) => {
          trackEvent(trackEventPayload.generalInfoFieldUpdated({ name: "cohort_date", value: value }));
        }}
        style={{
          width: 200,
        }}
      />
    </Form.Item>
  );
};
