import { InputSkeleton } from "@src/components/input-skeleton";
import { Form } from "antd";

type InputSkeletonLayoutProps = {
  children: React.ReactNode;
  width: number;
  label: string;
  required?: boolean;
  isLoading: boolean;
};
export const InputSkeletonLayout: React.FC<InputSkeletonLayoutProps> = ({
  children,
  width,
  isLoading,
  label,
  required,
}) => {
  return (
    <div
      style={{
        width: `${width}px`,
      }}
      className="mx-1 "
    >
      <div>
        <div
          style={{
            display: isLoading ? "block" : "none",
          }}
        >
          <Form.Item label={label} required={required}>
            <InputSkeleton />
          </Form.Item>
        </div>
        <div
          style={{
            display: isLoading ? "none" : "block",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
