import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { useGetOptionExtraField } from "@src/hooks/ab-testing/use-get-option-extra-field";
import { BaseSelectExtraField } from "./base-select-extra-field";

export const DeviceModal: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { isLoading, options } = useGetOptionExtraField({
    dimension: "device_model",
    fieldName: FilterFormFieldsEnum.EXTRA_DEVICE_MODAL,
    form,
  });

  return (
    <Form.Item
      className="w-[50%] max-w-[600px]"
      labelCol={{
        span: 6,
      }}
      name={FilterFormFieldsEnum.EXTRA_DEVICE_MODAL}
      label="Device Modal"
    >
      <BaseSelectExtraField
        fieldName={FilterFormFieldsEnum.EXTRA_DEVICE_MODAL}
        loading={isLoading}
        placeholder="Device Modal"
        options={options}
      />
    </Form.Item>
  );
};
