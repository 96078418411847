import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { Button, message } from "antd";
import { CSVLink } from "react-csv";
import { ChartActionProps } from ".";
import { DownloadOutlined } from "@ant-design/icons";
import { useTrackingEventMoreActionReportMetric } from "@src/hooks/ab-testing/metric-performance/use-tracking-event-more-action-report-metric";

export const ExportCSV: React.FC<ChartActionProps> = ({ tabKey, tabName }) => {
  const { handleTrackEvent } = useTrackingEventMoreActionReportMetric({
    chartKey: tabKey,
  });
  const { dataSources } = useChartWrapperContext();
  const [messageApi, contextHolder] = message.useMessage();

  const data = dataSources[tabKey] || [];
  const header = Object.keys(data[0] ?? {});

  return (
    <div>
      {contextHolder}
      <CSVLink filename={tabName} data={data} headers={header} className="text-black">
        <Button
          onClick={() => {
            messageApi.open({
              type: "success",
              content: "Successfully exported to CSV!",
            });
            handleTrackEvent({
              name: tabKey,
              buttonName: "export_csv",
            });
          }}
          type="text"
          disabled={!dataSources[tabKey]}
          className="w-full flex justify-start items-center"
        >
          <DownloadOutlined className="pb-1 text-[16px]" />
          Export CSV
        </Button>
      </CSVLink>
    </div>
  );
};
