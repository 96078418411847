import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum, MetricBreakDownTabsChartKeyEnum } from "@src/pages/ab-test-explore/enum";
import { MoreActionType, trackEventPayload } from "@src/util/track-event-payload";
import { Form } from "antd";
import { useTracking } from "react-tracking";

export const useTrackingEventMoreActionReportMetric = ({ chartKey }: { chartKey: string }) => {
  const { chartForm } = useABContext();
  const { trackEvent } = useTracking();
  const metricTabName = Form.useWatch([chartKey, ChartFormFieldsEnum.METRIC_TAB_NAME], chartForm);

  function handleTrackEvent({ name, buttonName }: { name?: string; buttonName: MoreActionType }) {
    if (chartKey === MetricBreakDownTabsChartKeyEnum.REPORT_DATA) {
      trackEvent(trackEventPayload.metricReportActionClick(metricTabName, buttonName));
    } else {
      name && trackEvent(trackEventPayload.chartButtonActionClick(name, buttonName));
    }
  }
  return { handleTrackEvent };
};
