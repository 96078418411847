import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CanAccess, useCan, useDelete, useNavigation } from "@refinedev/core";
import { Button, FormInstance, Popconfirm, Space, Tooltip } from "antd";
import { DuplicateButton } from "./duplicate-button";

export const ActionsColumn: React.FC<{ record: any; form: FormInstance<any>; refetch: () => void }> = ({
  record,
  form,
  refetch,
}) => {
  const { push } = useNavigation();
  const { mutateAsync: deleteExplore } = useDelete();

  const { data: dataCanEdit } = useCan({
    resource: "exploration",
    action: "edit",
    params: {
      productCode: record.product.code,
    },
  });

  const { data: dataCanDuplication } = useCan({
    resource: "exploration",
    action: "duplicate",
    params: {
      productCode: record.product.code,
    },
  });

  const { data: dataCanDelete } = useCan({
    resource: "exploration",
    action: "delete",
    params: {
      productCode: record.product.code,
    },
  });
  const handleDelete = async (id: any) => {
    form?.setFieldValue("form_loading", true);
    await deleteExplore({
      dataProviderName: "nestjsx",
      id,
      resource: `explorations`,
      successNotification: () => {
        return {
          type: "success",
          message: "Delete firebase exploration successfully!",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: "Delete firebase exploration failed!",
        };
      },
    });
    form?.setFieldValue("form_loading", false);
    refetch();
  };
  return (
    <Space
      className="w-full"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Tooltip title={dataCanEdit?.can ? "Edit" : dataCanEdit?.reason}>
        <Button
          icon={<EditOutlined />}
          size="small"
          onClick={() => {
            push(`/explorations/firebase/edit/${record.id}`);
          }}
          disabled={!dataCanEdit?.can}
        />
      </Tooltip>

      <DuplicateButton
        id={record.id}
        form={form}
        message={dataCanDuplication?.can ? "Duplicate" : dataCanDuplication?.reason}
        disabled={!dataCanDuplication?.can}
      />

      <Popconfirm
        title="Are you sure delete this exploration?"
        onConfirm={() => {
          handleDelete(record.id);
        }}
      >
        <Tooltip title={dataCanDelete?.can ? "Delete" : dataCanDelete?.reason}>
          <Button danger icon={<DeleteOutlined />} size="small" disabled={!dataCanDelete?.can} />
        </Tooltip>
      </Popconfirm>
    </Space>
  );
};
