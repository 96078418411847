import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { trackEventPayload } from "@src/util/track-event-payload";
import { Typography, SelectProps } from "antd";
import { useTracking } from "react-tracking";

const { Text } = Typography;
export const BaseSelectExtraField: React.FC<
  SelectProps<any, any> & {
    fieldName: string;
  }
> = ({ ...props }) => {
  const { trackEvent } = useTracking();

  return (
    <SelectWithSpinAndCustomMaxTag
      allowClear
      filterOption={(input, option: any) => {
        return (
          option?.value?.toLowerCase().includes(input.toLowerCase()) ||
          option?.label?.toLowerCase().includes(input.toLowerCase())
        );
      }}
      mode="multiple"
      showSearch
      optionRender={(opt) => {
        return (
          <div className="grid grid-flow-row-dense grid-cols-4">
            <div className="col-span-3">{opt.label}</div>
            <div className="col-span-1">
              <Text disabled>{opt.data.user_count} users</Text>
            </div>
          </div>
        );
      }}
      style={{
        width: 400,
      }}
      {...props}
      onChange={(value, option) => {
        props?.onChange?.(value, option);
        trackEvent(trackEventPayload.extraFieldFilter("field_update", { [props.fieldName]: value }));
      }}
    />
  );
};
