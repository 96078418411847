import { RangePresetsDefault } from "@src/constant/default-range-picker-presets";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { trackEventPayload } from "@src/util/track-event-payload";
import { DatePicker, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { useTracking } from "react-tracking";

const { RangePicker } = DatePicker;
export const ExtraOnboardingDate: React.FC<{ form: FormInstance }> = () => {
  const { trackEvent } = useTracking();
  return (
    <Form.Item
      className="w-[50%] max-w-[600px]"
      labelCol={{
        span: 6,
      }}
      name={FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE}
      label="Onboarding Date"
    >
      <RangePicker
        presets={RangePresetsDefault}
        style={{
          width: 400,
        }}
        onChange={(_, dateStrings) => {
          trackEvent(
            trackEventPayload.extraFieldFilter("field_update", {
              [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: dateStrings,
            }),
          );
        }}
      />
    </Form.Item>
  );
};
