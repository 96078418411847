import { ExclamationCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { trackEventPayload } from "@src/util/track-event-payload";
import { Card, Checkbox, Form, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";
import { useTracking } from "react-tracking";

export const FilterOutUser: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { trackEvent } = useTracking();
  return (
    <Card
      style={{
        boxShadow: "0 0 0 0",
      }}
      className="mb-8"
      title={<div className="flex">Filter out users </div>}
      css={css`
        .ant-form-item {
          margin-bottom: 0;
        }
        .ant-card-body {
          padding: 10;
        }
      `}
    >
      <div className="flex flex-wrap justify-around w-full">
        <Form.Item
          name={FilterFormFieldsEnum.INVALID_COUNTRIES}
          label={
            <span>
              Invalid countries{" "}
              <Tooltip title="Users recorded with different countries between two data sources: Appflyer & Firebase">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox
            onChange={(value) => {
              trackEvent(
                trackEventPayload.extraFieldFilter("field_update", {
                  [FilterFormFieldsEnum.INVALID_COUNTRIES]: value.target.checked,
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item
          name={FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS}
          label={
            <span>
              Users join multiple groups{" "}
              <Tooltip title="Users join multiple groups in a same experiment or multiple experiments in the observed time range">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox
            onChange={(value) => {
              trackEvent(
                trackEventPayload.extraFieldFilter("field_update", {
                  [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: value.target.checked,
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item
          name={FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID}
          label={
            <span>
              Multi user pseudo_id{" "}
              <Tooltip title="Users having more than 1 pseudo_id with the same install date is considered abnormal as Abalyzer defines users by combination of master_deivce_id + install_date">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox
            onChange={(value) => {
              trackEvent(
                trackEventPayload.extraFieldFilter("field_update", {
                  [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: value.target.checked,
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item
          name={FilterFormFieldsEnum.LATE_ONBOARDING_DATE}
          label={
            <span>
              Late onboarding date{" "}
              <Tooltip title="Users being assigned to an experiment more than 3 days after their install date will be removed in cohort view by Install date">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox
            onChange={(value) => {
              trackEvent(
                trackEventPayload.extraFieldFilter("field_update", {
                  [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: value.target.checked,
                }),
              );
            }}
          />
        </Form.Item>
      </div>
    </Card>
  );
};
