import { Button, Modal } from "antd";
import { ChartActionProps } from ".";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import React from "react";
import JsonViewer from "@src/components/query-builder/json-viewer";
import { DeploymentUnitOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import { useTrackingEventMoreActionReportMetric } from "@src/hooks/ab-testing/metric-performance/use-tracking-event-more-action-report-metric";

export const JSONQuery: React.FC<ChartActionProps> = ({ tabKey }) => {
  const { handleTrackEvent } = useTrackingEventMoreActionReportMetric({
    chartKey: tabKey,
  });
  const { queries, modelAliases, queryOptions } = useChartWrapperContext();
  const [open, setOpen] = React.useState(false);
  const modelAlias = modelAliases[tabKey];
  const query = queries[tabKey];
  const queryOption = queryOptions[tabKey];

  return (
    <div>
      <Button
        type="text"
        disabled={!query}
        onClick={() => {
          setOpen(true);
          handleTrackEvent({
            name: tabKey,
            buttonName: "view_json",
          });
        }}
        className="w-full flex justify-start items-center"
      >
        <DeploymentUnitOutlined className="pb-1 text-[16px]" />
        View JSON Query
      </Button>
      <Modal
        title="JSON Query"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => {
          setOpen(true);
        }}
        width={800}
      >
        <Descriptions
          bordered
          items={[
            {
              key: "model-alias",
              label: "Model",
              children: modelAlias,
              span: 3,
            },

            {
              key: "query",
              label: "Json query",
              children: (
                <div className="h-[600px] overflow-y-scroll">
                  <JsonViewer query={query} />
                </div>
              ),
              span: 3,
            },
            {
              key: "query-options",
              label: "Query options",
              children: (
                // <div className="h-[200px] overflow-y-scroll">
                <div className="h-full overflow-y-scroll">
                  <JsonViewer query={queryOption} />
                </div>
              ),
              span: 3,
            },
          ]}
        />
      </Modal>
    </div>
  );
};
