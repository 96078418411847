import { BarChartOutlined, TableOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { ChartDataTypeEnum, UseChartLayoutType } from "@src/hooks/ab-testing/use-chart-layout";
import { trackEventPayload } from "@src/util/track-event-payload";
import { Radio, Spin } from "antd";
import React from "react";
import { useTracking } from "react-tracking";
type ChartLayoutProps = {
  customTrackEvent?: (mode: string) => void;
  moreActions?: React.ReactNode;
  renderRaw?: React.ReactNode;
  renderChart?: React.ReactNode;
  loading?: boolean;
  defaultMode?: "chart" | "raw";
  hiddenMode?: boolean;
} & Partial<
  UseChartLayoutType & {
    chartKey: string;
  }
>;
type ChartMode = "chart" | "raw";
enum ChartModeEnum {
  CHART = "chart",
  RAW = "raw",
}

export const ChartLayoutWithUserPercent: React.FC<ChartLayoutProps> = ({
  customTrackEvent,
  moreActions,
  renderRaw,
  renderChart,
  loading = false,
  defaultMode = "chart",
  hiddenMode = false,
  chartKey,
  ...useChartLayoutProps
}) => {
  const { trackEvent } = useTracking();
  const [mode, setMode] = React.useState<ChartMode>(defaultMode);
  const { chartDataType, setChartDataType } = useChartLayoutProps;

  return (
    <Spin spinning={loading}>
      <div
        css={css`
          .ant-tabs-nav-wrap {
            justify-content: start;
          }
        `}
      >
        {moreActions}
        {mode === ChartModeEnum.RAW && <div className="pt-6">{renderRaw}</div>}
        {mode === ChartModeEnum.CHART && (
          <>
            {chartDataType && setChartDataType && (
              <Radio.Group
                value={chartDataType}
                onChange={(v) => {
                  setChartDataType?.(v.target.value);
                  if (customTrackEvent) {
                    customTrackEvent(v.target.value);
                  } else {
                    chartKey && trackEvent(trackEventPayload.chartTypeSelect(chartKey, v.target.value));
                  }
                }}
              >
                <Radio.Button value={ChartDataTypeEnum.USER_PERCENT}>User Percent</Radio.Button>
                <Radio.Button value={ChartDataTypeEnum.USER_COUNT}>User Count</Radio.Button>
              </Radio.Group>
            )}

            <div className="pb-8" />
            {renderChart}
          </>
        )}
        {!hiddenMode && (
          <div className="flex justify-center w-full mt-[40px]">
            <Radio.Group
              value={mode}
              onChange={(v) => {
                setMode?.(v.target.value);
                if (customTrackEvent) {
                  customTrackEvent(v.target.value);
                } else {
                  chartKey && trackEvent(trackEventPayload.chartModeSelect(chartKey, v.target.value));
                }
              }}
            >
              <Radio.Button value={ChartModeEnum.CHART}>
                <BarChartOutlined />
              </Radio.Button>
              <Radio.Button value={ChartModeEnum.RAW}>
                <TableOutlined />
              </Radio.Button>
            </Radio.Group>
          </div>
        )}
      </div>
    </Spin>
  );
};
