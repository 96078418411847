import { useCreate, useNavigation, useOne, useParsed } from "@refinedev/core";
import { getUserInfo } from "@src/util/get-user-info";
import { FormInstance } from "antd/lib/form";
import React from "react";

export const useDuplicateFirebaseExplore = ({
  id,
  redirect = true,
  replace = true,
  cb,
  form,
}: {
  id?: string;
  redirect?: boolean;
  replace?: boolean;
  cb?: () => void;
  form?: FormInstance<any>;
}) => {
  const navigation = useNavigation();
  const [_id, setId] = React.useState<string | undefined>(id);
  const { params } = useParsed();
  const productCode = params?.product_code;
  const { refetch } = useOne({
    dataProviderName: "nestjsx",
    id: _id,
    resource: `explorations`,
    queryOptions: {
      enabled: false,
    },
    meta: {
      join: [
        {
          field: "product",
        },
      ],
      headers: {
        "Product-Code": productCode,
      },
    },
  });
  const { mutateAsync: createExplore } = useCreate();

  const onDuplicate = () => {
    form?.setFieldValue("form_loading", true);
    refetch()
      .then((data) => {
        if (!data?.data) return;
        const { title, ...restData } = data?.data?.data as any;
        const userInfo = getUserInfo();
        const productCode = restData.product?.code;
        const excludesFields = ["id", "created_at", "updated_at", "deleted_at", "owner"];
        excludesFields.forEach((field) => {
          delete restData[field];
        });

        createExplore({
          dataProviderName: "nestjsx",
          resource: "explorations",
          values: {
            title: title + " COPY",
            ...restData,
            owner: userInfo?.email,
          },
          meta: {
            headers: {
              "Product-Code": productCode,
            },
          },
          successNotification: (data) => {
            cb && cb();
            if (redirect) {
              const newId = data?.data?.id;
              const url = `/explorations/firebase/edit/${newId}`;
              replace ? navigation.replace(url) : navigation.push(url);
            }
            return {
              type: "success",
              message: "Clone firebase exploration successfully!",
            };
          },
          errorNotification: () => {
            return {
              type: "error",
              message: "Clone firebase exploration failed!",
            };
          },
        }).finally(() => {
          form?.setFieldValue("form_loading", false);
        });
      })
      .catch(() => {
        form?.setFieldValue("form_loading", false);
      });
  };
  const handleDuplicate = async ({ id }: { id?: string }) => {
    if (!id) {
      onDuplicate();
      return;
    }
    setId(id);
  };

  React.useEffect(() => {
    if (!_id) return;
    if (_id === id) return;
    onDuplicate();
  }, [_id]);

  return { handleDuplicate };
};
