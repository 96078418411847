import { AutoCompleteWrapper } from "@src/components/autocomplete/autocomplete-wrapper";
import TimeGroupITem from "@src/components/query-builder/time-group-item";
import { useExploreContext } from "@src/contexts/explore-context";
import { IProduct } from "@src/types/user";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { Form, FormInstance, Input } from "antd";
import React from "react";
import { PlatformField } from "./platform-field";

export const GeneralInfoWrapper: React.FC<{ mode: "create" | "edit"; form?: FormInstance<object> }> = ({
  form,
  mode,
}) => {
  const { isViewMode } = getModeOfLocation();
  const { setProduct, product, updateTimeDimensions } = useExploreContext();
  const [isEditingAutoComplete, setIsEditingAutoComplete] = React.useState(false);
  const onSetProduct = (value: IProduct) => {
    setProduct(value);
    const product_code = value.productCode;
    form?.setFieldValue("product_code", product_code);
  };

  return (
    <div>
      <div className=" w-full flex items-center space-x-2 mb-4">
        <Form.Item
          label={<span className="font-bold">Product</span>}
          className="w-[25%] mb-0"
          rules={[
            {
              required: true,
              message: "Please select product",
            },
          ]}
          name={"product_code"}
        >
          <AutoCompleteWrapper
            setProduct={onSetProduct}
            product={product}
            isEditing={isViewMode ? false : isEditingAutoComplete}
            setIsEditing={setIsEditingAutoComplete}
          />
        </Form.Item>
        <PlatformField form={form} />
        <Form.Item
          name="name"
          label={<span className="font-bold">Title</span>}
          className="w-[25%] mb-0 pr-2"
          rules={[
            {
              required: true,
              message: "Please enter title",
            },
          ]}
        >
          <Input disabled={!!isViewMode} type="text" name="name" placeholder="Title" />
        </Form.Item>
        <Form.Item label={<span className="font-bold">Time range</span>} className="w-[30%] mb-0">
          <div className="flex items-center space-x-2 ">
            <TimeGroupITem updateMethods={updateTimeDimensions} />
          </div>
        </Form.Item>
      </div>
      <Form.Item name="description" label={<span className="font-bold">Description</span>} className="mb-0 ">
        <Input disabled={!!isViewMode} placeholder="Description" style={{ width: 600 }} />
      </Form.Item>
    </div>
  );
};
