import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { config } from "@src/config";
import { useDebounce } from "@src/hooks/use-debounce";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { isArrayAndNotEmpty } from "@src/util/common/array";
import { useTracking } from "react-tracking";
import { trackEventPayload } from "@src/util/track-event-payload";

export const ExtraButton: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  const { trackEvent } = useTracking();
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const variantWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS, form);
  const amaAppIdWatch = Form.useWatch(FilterFormFieldsEnum.AMA_APP_IDS, form);
  const isGeneralFilterReady =
    productCodeWatch && abaIdWatch && isArrayAndNotEmpty(amaAppIdWatch) && isArrayAndNotEmpty(variantWatch);

  const isShowChipWatch = Form.useWatch(FilterFormFieldsEnum.IS_SHOW_CHIP, form);
  const filterValuesConfirmed = Form.useWatch(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED, form) ?? "{}";
  const filterValuesConfirmedWatch = useDebounce(
    JSON.parse(filterValuesConfirmed ?? "{}"),
    config.DEBOUNCE_TIME.SHORT_DELAY,
  );

  const numOfFilterExtraApplied =
    Object.keys(filterValuesConfirmedWatch ?? {}).filter((field) => filterValuesConfirmedWatch[field] !== undefined)
      .length ?? 0;

  const handleShowChip = () => {
    const isShow = !form.getFieldValue(FilterFormFieldsEnum.IS_SHOW_CHIP);
    form.setFieldValue(FilterFormFieldsEnum.IS_SHOW_CHIP, isShow);
  };

  return (
    <div key={filterValuesConfirmed} className="flex items-center">
      <Button type="text" className="p-2 flex items-center mr-2" onClick={() => handleShowChip()}>
        {isShowChipWatch ? (
          <FilterOutlined className="text-[18px] text-blue-500" />
        ) : (
          <FilterFilled className="text-[18px] text-blue-500" />
        )}
      </Button>
      <Button
        disabled={!isGeneralFilterReady}
        type="primary"
        // className="text-blue-500 border-blue-500"
        onClick={() => {
          form.setFieldValue(FilterFormFieldsEnum.IS_OPEN_MODAL, true);
          trackEvent(
            trackEventPayload.extraFieldFilter("button_click", { page_section: "general_info", button_name: "open" }),
          );
        }}
      >
        Extra filter {numOfFilterExtraApplied > 0 ? `(${numOfFilterExtraApplied})` : ""}
      </Button>
    </div>
  );
};
