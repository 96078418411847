import { useForm } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { config } from "@src/config";
import { FORM_NAME, SEARCH_PARAMS_KEY } from "@src/constant/ab-testing/user-count";
import { ChartFormFieldsEnum, FilterFormFieldsEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import { getGroupFieldValue, getParamsFieldValue } from "@src/util/ab-testing/params";
import { URLParamsManager } from "@src/util/url-params-manager";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import React from "react";
import { createContext, useContext } from "react";
import { NavigateFunction } from "react-router-dom";

export type FilterFormInstanceType = {
  [FilterFormFieldsEnum.ABA_ID]: string;
  [FilterFormFieldsEnum.AMA_APP_IDS]: string[];
  [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: boolean;
  [FilterFormFieldsEnum.IS_FIRST_APPLIED]: boolean;
  [FilterFormFieldsEnum.PLATFORM]: string[];
  [FilterFormFieldsEnum.PRODUCT_CODE]: string;
  [FilterFormFieldsEnum.VARIANTS]: string[];
  [FilterFormFieldsEnum.VARIANTS_OPTIONS]: { label: string | undefined; value: string }[];
  [FilterFormFieldsEnum.COHORT_DATE]: string;
  [FilterFormFieldsEnum.MAX_DAY_DIFF]: number;

  //extra fields
  [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: string[] | undefined;
  [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: string[] | undefined;

  [FilterFormFieldsEnum.INVALID_COUNTRIES]: boolean;
  [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: boolean;
  [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: boolean;
  [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: boolean;

  [FilterFormFieldsEnum.EXTRA_COUNTRY]: string[];
  [FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE]: string[];
  [FilterFormFieldsEnum.EXTRA_DEVICE_MODAL]: string[];
  [FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY]: string[];
  [FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION]: string[];
  [FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION]: string[];
  [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: string;
};

export type ChartFormInstanceType = {
  [ChartFormFieldsEnum.FILTER_FIELDS]: string;
  [ChartFormFieldsEnum.TIME_RANGE]: string;
  [ChartFormFieldsEnum.CUSTOM_TIME_RANGE]: string[] | undefined;
  [ChartFormFieldsEnum.MAX_DAY_DIFF]: number;

  [ChartFormFieldsEnum.AD_NETWORK]: string[];
  [ChartFormFieldsEnum.AD_UNITS]: string[];
  [ChartFormFieldsEnum.PACKAGE]: string[];

  [ChartFormFieldsEnum.END_DATE]: string | undefined;
  [ChartFormFieldsEnum.START_DATE]: string | undefined;

  [ChartFormFieldsEnum.EVENT_NAME]: string;
  [ChartFormFieldsEnum.EVENT_PARAM]: string;
  [ChartFormFieldsEnum.EVENT_PARAM_OPTIONS]: any[];
  [ChartFormFieldsEnum.PARAM_VALUE]: string[];
  [ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT]: boolean;

  [ChartFormFieldsEnum.METRIC_TIME_RANGE]: string[] | undefined;
  [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: number | undefined;
  [ChartFormFieldsEnum.METRIC_BASE_VARIANTS]: string[] | undefined;
  [ChartFormFieldsEnum.METRIC_TAB_ITEMS]: any[];
} & Record<string, any>;

type ABContextType = {
  filterForm: FormInstance<FilterFormInstanceType>;
  chartForm: FormInstance<ChartFormInstanceType>;
  updateSearchUrl: (
    initData?: {
      filterForm?: FilterFormInstanceType;
      chartForm?: ChartFormInstanceType;
    },
    navigate?: NavigateFunction,
  ) => void;

  getParamsFieldValue: (
    field: FilterFormFieldsEnum | ChartFormFieldsEnum | TabsViewParamsEnum,
    location: "filterForm" | "chartForm" | "tabsConfig",
  ) => any;
};
export const ABContext = createContext({} as ABContextType);

type IProps = {
  children: React.ReactNode;
};

export const ABContextProvider: React.FC<IProps> = ({ children }) => {
  const urlParamsManager = new URLParamsManager();

  const tabsConfigRef = React.useRef<any>(getGroupFieldValue("tabsConfig") ?? {});

  const { form: filterForm } = useForm<any, HttpError, FilterFormInstanceType>();
  const { form: chartForm } = useForm<any, HttpError, ChartFormInstanceType>();

  const getDataOfFilterForm = (e: FilterFormInstanceType) => {
    const result: any = {};
    Object.keys(e).forEach((key) => {
      switch (key) {
        case FilterFormFieldsEnum.ABA_ID:
        case FilterFormFieldsEnum.AMA_APP_IDS:
        case FilterFormFieldsEnum.PLATFORM:
        case FilterFormFieldsEnum.PRODUCT_CODE:
        case FilterFormFieldsEnum.VARIANTS:
        case FilterFormFieldsEnum.VARIANTS_OPTIONS:
        case FilterFormFieldsEnum.COHORT_DATE:
        case FilterFormFieldsEnum.MAX_DAY_DIFF:
          result[key] = e[key];
          break;
        case FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED: {
          const values = JSON.parse(e[key] ?? "{}");

          Object.keys(values)?.forEach((field) => {
            result[field] = values[field];
          });
          break;
        }
      }
    });
    return result;
  };
  const getDataOfChartForm = (e: ChartFormInstanceType) => {
    const result: any = {};
    Object.keys(e).forEach((key) => {
      if (
        [ChartFormFieldsEnum.CUSTOM_TIME_RANGE, ChartFormFieldsEnum.METRIC_TIME_RANGE].includes(
          key as ChartFormFieldsEnum,
        )
      ) {
        result[key] = e[key] ? e[key].map((item: string) => dayjs(item).format(config.DATE_FORMAT)) : undefined;
      } else {
        result[key] = e[key as ChartFormFieldsEnum];
      }
    });

    (result[ChartFormFieldsEnum.METRIC_TAB_ITEMS] ?? [])?.forEach((item: any) => {
      if (item.key) {
        delete result[item.key];
      }
    });

    return result;
  };

  const updateSearchUrl = (
    initData?: {
      filterForm?: FilterFormInstanceType;
      chartForm?: ChartFormInstanceType;
    },
    navigate?: NavigateFunction,
  ) => {
    const params = {
      [FORM_NAME.FILTER_FORM]: getDataOfFilterForm(initData?.filterForm ?? filterForm.getFieldsValue()),
      [FORM_NAME.CHART_FORM]: getDataOfChartForm(initData?.chartForm ?? chartForm.getFieldsValue()),
      tabsConfig: tabsConfigRef.current,
    };

    urlParamsManager.addParam(SEARCH_PARAMS_KEY.CONFIG, btoa(JSON.stringify(params)), navigate);
  };

  const values = { filterForm, chartForm, updateSearchUrl, getParamsFieldValue };
  return <ABContext.Provider value={values}>{children}</ABContext.Provider>;
};

export const useABContext = () => useContext(ABContext);
