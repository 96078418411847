import { DatePicker, DatePickerProps, Form, TimeRangePickerProps } from "antd";
import { config } from "@src/config";
import dayjs from "dayjs";
import { css } from "@emotion/react";

const { RangePicker } = DatePicker;
type IProps = {
  value?: string[];
  onChange: (_: any, dateStrings: [string, string]) => void;
  disabled?: boolean;
};

function TimeDateRanger({ value = [], onChange, disabled = false }: IProps) {
  const disabledDate: DatePickerProps["disabledDate"] = (current, { from }) => {
    if (current && dayjs(current).isAfter(dayjs().subtract(1, "day"))) {
      return true;
    }
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 31;
    }
    return false;
  };

  const RangePresetsDefault: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-8, "d"), dayjs().add(-1, "d")] },
    { label: "Last 14 Days", value: [dayjs().add(-15, "d"), dayjs().add(-1, "d")] },
    { label: "Last 30 Days", value: [dayjs().add(-31, "d"), dayjs().add(-1, "d")] },
  ];

  return (
    <Form.Item
      css={css`
        .ant-picker-range {
          background-color: ${disabled ? "#F7F8F9" : "white"};
        }
      `}
      className="m-0 "
      name="custom-time-range"
      initialValue={[
        value?.length > 0 ? dayjs(value[0], config.DATE_FORMAT) : null,
        value?.length > 0 ? dayjs(value[1], config.DATE_FORMAT) : null,
      ]}
      rules={[
        {
          validator: async (_, value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              return Promise.resolve();
            } else {
              return Promise.reject("Please select time range");
            }
          },
        },
      ]}
    >
      <RangePicker
        disabledDate={disabledDate}
        disabled={!!disabled}
        onChange={onChange}
        presets={RangePresetsDefault}
      />
    </Form.Item>
  );
}

export default TimeDateRanger;
