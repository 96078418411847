import React from "react";
import { Authenticated, CanAccess, Refine } from "@refinedev/core";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerBindings, { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import dataProvider from "@refinedev/simple-rest";

import { ReactFlowProvider } from "reactflow";

// antd icons
import { FireOutlined, FundOutlined, PicLeftOutlined, PieChartOutlined, ToolOutlined } from "@ant-design/icons";

// components

// configs
import { PAGE_URL, config } from "./config";

// contexts
import { AppContextProvider } from "@src/contexts/app-context";
import { MultiStepQueryContextProvider } from "./contexts/multi-step-query-context";

// pages
import { FunnelAnalysis } from "@src/pages/funnel-analysis";
import { PathAnalysis } from "@src/pages/path-analysis";
import { DrillerAnalysis } from "@src/pages/driller-analysis";
import { MultiStepQuery } from "@src/pages/multi-step-query";
import { SavedExploration } from "@src/pages/workpsace/saved-exploration";
import { Error401 } from "@src/pages/error/401";
import { Error403 } from "@src/pages/error/403";
import { Funnel } from "@src/pages/advanced-analytics/funnel";
import { ListExplore } from "./pages/explore/list";
import { ConfigProvider, Spin } from "antd";
import { themeConfig } from "./config/antd";
import { Layout } from "./components";
import { AdminAuth } from "./auth/AdminAuth";
import { ProductListing } from "./pages/admin/product/list";
import enUS from "antd/lib/locale/en_US";
import { FirebaseExploreCreate } from "./pages/explore/component/firebase/create";
import { FirebaseExploreEdit } from "./pages/explore/component/firebase/edit";
import { ExploreContextProvider } from "./contexts/explore-context";
import { ABTestExplore } from "./pages/ab-test-explore/list";
import { FirebaseExploreShareView } from "./pages/explore/component/firebase/share-view";
import { ABContextProvider } from "./contexts/ab-testing/ab-context";
import Login from "./pages/auth/login";
import LoginRedirect from "./pages/auth/login-redirect";
import { can } from "./auth/can";
import { nestjsxProvider } from "./libs/nestjsx-provider";
import { Result403 } from "./components/result/result-403";
import { ABTestExploreRedirect } from "./pages/ab-test-explore/redirect";
import { FirebaseExploreShow } from "./pages/explore/component/firebase/show";
import track from "react-tracking";
import { useAuth0Provider } from "./libs/auth0-provider";
import { LoadingFullScreen } from "./components/loading/loading-full-screen";
import { dispatchLogEvent } from "./libs/firebase-log-event";

function App() {
  const { axios, auth0Provider, isLoading, isLoadingAPI } = useAuth0Provider();
  if (isLoading) {
    return <LoadingFullScreen />;
  }
  return (
    <Spin spinning={isLoading || isLoadingAPI} className="h-screen max-h-full min-h-full w-full ">
      <div className="min-h-screen">
        <BrowserRouter>
          <ConfigProvider locale={enUS} theme={themeConfig}>
            <Refine
              dataProvider={{
                default: dataProvider(config.BACKEND_URL, axios),
                nestjsx: nestjsxProvider(config.NESTJS_URL + "/api", axios),
              }}
              notificationProvider={useNotificationProvider}
              routerProvider={routerBindings}
              authProvider={auth0Provider}
              accessControlProvider={{
                can,
              }}
              resources={[
                {
                  name: "exploration",
                  meta: {
                    icon: <PicLeftOutlined />,
                  },
                },
                {
                  name: "Firebase",
                  list: "/explorations",
                  edit: "/explorations/firebase/edit/:id",
                  show: "/explorations/firebase/show/:id",
                  create: "/explorations/firebase/create",
                  meta: {
                    parent: "exploration",
                    icon: <FundOutlined />,
                    label: "Firebases",
                  },
                },
                {
                  name: "shared-exploration",
                  show: "explorations/firebase/share/:id",
                  meta: {
                    hide: true,
                    resourceOverride: "exploration",
                  },
                },

                {
                  name: "A/B Test Exploration",
                  list: "/ab-test-explorations",
                  meta: {
                    parent: "exploration",
                    icon: <PieChartOutlined />,
                    label: "A/B Test Exploration",
                    actionOverride: "show",
                    resourceOverride: "ab_test_exploration",
                  },
                },
                {
                  name: "Admin",
                  meta: {
                    icon: <ToolOutlined />,
                  },
                },
                {
                  name: "product",
                  list: "admin/products",
                  meta: {
                    parent: "Admin",
                    icon: <FireOutlined />,
                  },
                },
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                reactQuery: {
                  clientConfig: {
                    defaultOptions: {
                      queries: {
                        retry: 0,
                      },
                    },
                  },
                },
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                      <AppContextProvider>
                        <Layout>
                          <Outlet />
                        </Layout>
                      </AppContextProvider>
                    </Authenticated>
                  }
                >
                  <Route index element={<NavigateToResource resource="exploration" />} />
                  <Route
                    element={
                      <AdminAuth>
                        <Outlet />
                      </AdminAuth>
                    }
                    path="/admin"
                  >
                    <Route
                      path={"products"}
                      element={
                        <CanAccess resource="product" action="list" fallback={<Result403 />}>
                          <ProductListing />
                        </CanAccess>
                      }
                    />
                  </Route>

                  <Route path={PAGE_URL.FUNNEL_ANALYSIS} element={<FunnelAnalysis />} />
                  <Route path={PAGE_URL.PATH_ANALYSIS} element={<PathAnalysis />} />
                  <Route path={PAGE_URL.DATA_DRILLER} element={<DrillerAnalysis />} />
                  <Route
                    path={PAGE_URL.MULTI_STEP_QUERY}
                    element={
                      <ReactFlowProvider>
                        <MultiStepQueryContextProvider>
                          <MultiStepQuery />
                        </MultiStepQueryContextProvider>
                      </ReactFlowProvider>
                    }
                  />
                  <Route path={PAGE_URL.WORKSPACE.SAVED_EXPLORATION} element={<SavedExploration />} />
                  <Route path={PAGE_URL.ADVANCED_ANALYTICS.FUNNEL} element={<Funnel />} />
                  <Route path="*" element={<ErrorComponent />} />
                  <Route path="/explorations">
                    <Route index element={<ListExplore />} />
                    <Route
                      path="firebase/create"
                      element={
                        <ExploreContextProvider key="explore-context-create">
                          <FirebaseExploreCreate />
                        </ExploreContextProvider>
                      }
                    />
                    <Route
                      path="firebase/show/:id"
                      element={
                        <ExploreContextProvider key="explore-context-show">
                          <FirebaseExploreShow />
                        </ExploreContextProvider>
                      }
                    />
                    <Route
                      path="firebase/edit/:id"
                      element={
                        <ExploreContextProvider key="explore-context-edit">
                          <FirebaseExploreEdit />
                        </ExploreContextProvider>
                      }
                    />
                  </Route>
                  <Route
                    path="explorations/firebase/share/:id"
                    element={
                      <ExploreContextProvider key="explore-context-share-view">
                        <FirebaseExploreShareView />
                      </ExploreContextProvider>
                    }
                  />
                  <Route path="ab-test-explorations">
                    <Route
                      index
                      element={
                        <ABContextProvider>
                          <ABTestExplore />
                        </ABContextProvider>
                      }
                    />
                  </Route>
                  <Route path="*" element={<ErrorComponent />} />
                </Route>

                <Route path="ab-test-explorations">
                  <Route
                    index
                    element={
                      <ABContextProvider>
                        <ABTestExplore />
                      </ABContextProvider>
                    }
                  />
                  <Route path="redirect" element={<ABTestExploreRedirect />} />
                </Route>

                <Route
                  element={
                    <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route path={PAGE_URL.LOGIN} element={<Login />} />
                </Route>

                {/* Error Route */}
                <Route
                  path={PAGE_URL.ERROR}
                  element={
                    <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                      <Outlet />
                    </Authenticated>
                  }
                >
                  <Route path={PAGE_URL.ERROR_401} element={<Error401 />} />
                  <Route path={PAGE_URL.ERROR_403} element={<Error403 />} />
                </Route>
                <Route path="/auth/callback" element={<LoginRedirect />} />
              </Routes>
            </Refine>
          </ConfigProvider>
        </BrowserRouter>
      </div>
    </Spin>
  );
}

const TrackedApp = track(
  { app: "sat" },

  {
    dispatch: (data) => {
      if (data?.event) {
        dispatchLogEvent(data?.event?.name, data?.event?.params);
      }
    },
  },
)(App);

export default TrackedApp;
