import React, { createContext, PropsWithChildren, useContext } from "react";

// types
import { IChart, IChartProduct } from "@src/types/chart";

// hooks
import { useLocalStorage } from "@src/hooks/use-localstorage";
import { IProduct } from "@src/types/user";
import { NAME_STORAGE } from "@src/config/storage";

// mocks
import { IPivotConfig } from "@src/types/query-builder";
import { omit } from "lodash";
import { DEFAULT_QUERY_BUILDER } from "@src/config/query-builder";
import { useList } from "@refinedev/core";
import AmanotesIcon from "@src/assets/images/amanotes_icon.png";

type AppContextType = {
  isProductLoading?: boolean;
  charts: IChartProduct | null;
  product: IProduct | null;
  products: IProduct[];
  chartDefault: IChart | null;
  isOpenExploreModal: boolean;
  forceUpdate: number;
  isDisableDragGrid: boolean;
  defaultQuery: any;
  runOnceEditOrder: React.MutableRefObject<boolean>;
  isStartEditOrder: boolean;
  addChart: (query: IChart, productCode: string) => void;
  editChart: (query: IChart, productCode: string) => void;
  deleteChart: (chartId: number, productCode: string) => void;
  changeChartType: (chartType: string, chartId: number, productCode: string) => void;
  setProduct: React.Dispatch<React.SetStateAction<any>>;
  setCharts: React.Dispatch<React.SetStateAction<IChartProduct | null>>;
  setChartDefault: React.Dispatch<React.SetStateAction<IChart | null>>;
  setIsOpenExploreModal: React.Dispatch<React.SetStateAction<boolean>>;
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>;
  setIsDisableDragGrid: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultQuery: React.Dispatch<React.SetStateAction<any>>;
  setIsStartEditOrder: React.Dispatch<React.SetStateAction<boolean>>;
  changePivot: (pivotConfig: IPivotConfig, chartId: number, productCode: string) => void;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // refs
  const runOnceEditOrder = React.useRef(true);
  // storage
  const [productLocalStorage] = useLocalStorage<IProduct>(NAME_STORAGE.PRODUCT);
  const [chartStorage, setChartStorage] = useLocalStorage<IChartProduct | null>(NAME_STORAGE.CHARTS, null);
  // states
  const [isStartEditOrder, setIsStartEditOrder] = React.useState(false);
  const [product, setProduct] = React.useState<IProduct | null>(null);
  const [isOpenExploreModal, setIsOpenExploreModal] = React.useState(false);
  const [defaultQuery, setDefaultQuery] = React.useState(DEFAULT_QUERY_BUILDER);
  const [charts, setCharts] = React.useState<IChartProduct | null>(chartStorage);
  const [chartDefault, setChartDefault] = React.useState<IChart | null>(null);
  const [forceUpdate, setForceUpdate] = React.useState(Date.now());
  const [isDisableDragGrid, setIsDisableDragGrid] = React.useState(false);

  const { data, isFetching, isLoading } = useList<IProduct>({
    resource: "products",
    dataProviderName: "nestjsx",

    pagination: {
      pageSize: 100,
    },
  });
  const products = (data?.data || []).map((item) => {
    return {
      ...item,
      productCode: item.code,
      icon: item.icon || AmanotesIcon,
    };
  });
  // initialize apps once time from local storage
  React.useEffect(() => {
    if (isLoading) return;
    // set product from local storage
    setProduct(productLocalStorage || products[0]);

    // set charts from local storage
    if (chartStorage) {
      setCharts(chartStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // save actions chart to local storage
  React.useEffect(() => {
    setChartStorage(charts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charts]);

  function addChart(chart: IChart, productCode: string) {
    if (!product) return;
    const newChart = {
      ...charts,
      [productCode]: {
        ...(charts?.[productCode] || {}),
        [chart.id]: chart,
      },
    };
    setCharts(newChart);
  }

  function editChart(chart: IChart, productCode: string) {
    if (!product) return;
    const newChart = {
      ...charts,
      [productCode]: {
        ...(charts?.[productCode] || {}),
        [chart.id]: chart,
      },
    };
    setCharts(newChart);
  }

  function deleteChart(chartId: number, productCode: string) {
    if (!product || !charts) return;
    const chartsOfProduct = charts[productCode] || null;
    if (Object.keys(chartsOfProduct).length > 1) {
      delete chartsOfProduct[chartId];
      setCharts((prevState) => ({
        ...prevState,
        [productCode]: chartsOfProduct,
      }));
    } else {
      setCharts(omit(charts, productCode));
    }
  }

  function changeChartType(chartType: string, chartId: number, productCode: string) {
    if (!product || !charts) return;
    const newChart = {
      ...charts,
      [productCode]: {
        ...charts[productCode],
        [chartId]: {
          ...charts[productCode][chartId],
          vizState: {
            ...charts[productCode][chartId].vizState,
            chartType,
          },
        },
      },
    };
    setCharts(newChart);
  }

  function changePivot(pivotConfig: IPivotConfig, chartId: number, productCode: string) {
    if (!product || !charts) return;
    const newChart = {
      ...charts,
      [productCode]: {
        ...charts[productCode],
        [chartId]: {
          ...charts[productCode][chartId],
          vizState: {
            ...charts[productCode][chartId].vizState,
            pivotConfig,
          },
        },
      },
    };
    setCharts(newChart);
  }

  return (
    <AppContext.Provider
      value={{
        // states
        isProductLoading: isFetching,
        charts,
        product,
        products,
        isOpenExploreModal,
        forceUpdate,
        isDisableDragGrid,
        defaultQuery,
        runOnceEditOrder,
        isStartEditOrder,
        // actions
        setProduct,
        addChart,
        editChart,
        deleteChart,
        changeChartType,
        setCharts,
        chartDefault,
        setChartDefault,
        setIsOpenExploreModal,
        setForceUpdate,
        setIsDisableDragGrid,
        setDefaultQuery,
        setIsStartEditOrder,
        changePivot,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
