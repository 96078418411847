import { Create, ListButton, SaveButton, useForm } from "@refinedev/antd";
import { useExploreContext } from "@src/contexts/explore-context";
import React from "react";
import { useCan, useNavigation } from "@refinedev/core";
import { Button, Card, Form } from "antd";
import { GeneralInfoWrapper } from "./form/general-info-wrapper";
import ExploreQueryNew from "@src/pages/dashboard/components/explore-builder-new";
import { ChartResultProvider } from "./form/chart-result-provider";
import { QueryBuilderChartLayout } from "@src/layouts/firebase-explore/query-builder-chart-layout";
import { getUserInfo } from "@src/util/get-user-info";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const FirebaseExploreCreate: React.FC = () => {
  const { push, replace } = useNavigation();
  const { query, chartType, pivotConfig, product, form: formContext } = useExploreContext();
  const { formProps } = useForm({
    resource: `explorations`,
    dataProviderName: "nestjsx",
    redirect: false,
    action: "create",
    onMutationSuccess: (res) => {
      const newId = res?.data?.id;
      replace(`/explorations/firebase/edit/${newId}`);
    },
    meta: {
      headers: {
        "product-code": product?.code,
      },
    },
  });

  const form = formContext ?? formProps.form;

  const handleFinish = async (e: any) => {
    form?.validateFields();
    const payload = {
      chart_type: chartType,
      pivot_config: pivotConfig,
      filters: query?.filters,
      dimensions: query?.dimensions,
      measures: query?.measures,
      segments: query?.segments,
      order: query?.order,
      time_dimensions: query?.timeDimensions,
    };
    const userInfo = getUserInfo();
    const values = {
      title: e.name,
      description: e?.description ?? "",
      is_public: false,
      owner: userInfo?.email,
      config: payload,
      type: "Firebase",
      product_code: product?.code,
    };

    formProps.onFinish?.(values);
  };
  const platformWatch = Form.useWatch("platform", form);
  const isEmptyPlatform = !platformWatch || platformWatch.length === 0;

  const { data } = useCan({
    resource: "exploration",
    action: "create",
    params: {
      productCode: product?.code,
    },
  });
  const canCreate = data?.can;
  return (
    <Create
      title="Create exploration"
      headerButtons={<ListButton onClick={() => push("/")}>Explorations</ListButton>}
      contentProps={{
        className: "bg-transparent shadow-none card-custom",
      }}
      footerButtonProps={{
        hidden: true,
      }}
      goBack={
        <Button
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            push("/");
          }}
        >
          <ArrowLeftOutlined />
        </Button>
      }
      wrapperProps={{
        className: "base-custom-container",
      }}
    >
      <Form preserve layout="vertical" {...formProps} form={form} onFinish={handleFinish}>
        <Card size="small" title={"General Information"} className="mb-2">
          <div className="flex mb-4 ">
            <div className="w-3/4">
              <GeneralInfoWrapper form={form} mode="create" />
            </div>
            <div className="w-1/4">
              <SaveButton
                disabled={isEmptyPlatform || !canCreate}
                htmlType="submit"
                className="absolute bottom-10 right-2"
              >
                Save
              </SaveButton>
            </div>
          </div>
        </Card>

        {product && (
          <QueryBuilderChartLayout
            queryBuilderComponent={<ExploreQueryNew />}
            showResultComponent={<ChartResultProvider mode="create" form={form} />}
          />
        )}
      </Form>
    </Create>
  );
};
