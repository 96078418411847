import { SaveButton } from "@refinedev/antd";
import { ButtonProps, Drawer, DrawerProps, Form, FormInstance, FormProps, Skeleton } from "antd";
import { useApiUrl, useCustom } from "@refinedev/core";
import { useImportProduct } from "@src/hooks/use-import-product";
import React from "react";
import { ImportProduct } from "@src/components/import/import-product";
import { GeneralBodyForm } from "./components/form/general-body-form";

interface ProductCreateProps {
  drawerProps: DrawerProps;
  saveButtonProps: ButtonProps & {
    onClick: () => void;
  };
  formProps: FormProps<object> & {
    form: FormInstance<object>;
  };
  onCreate: (product: any) => void;
  close: () => void;
}

export const ProductCreate: React.FC<ProductCreateProps> = ({ drawerProps, saveButtonProps, formProps, close }) => {
  const apiUrl = useApiUrl("nestjsx");
  const { data, isLoading } = useCustom({
    url: `${apiUrl}/proxy/v2/analytic-events/template`,
    method: "get",
    dataProviderName: "nestjsx",
  });
  const analytic_events = data?.data;
  const { data: dataImport, setData } = useImportProduct();
  React.useEffect(() => {
    formProps.form.resetFields();
  }, [dataImport, formProps.form]);
  return (
    <Drawer
      {...drawerProps}
      title={
        <div className="flex justify-between items-center">
          <span>Create product</span>
          <ImportProduct setData={setData} />
        </div>
      }
      width={1000}
      footer={
        <div className="w-full flex justify-end/">
          <SaveButton {...saveButtonProps} />
        </div>
      }
      onClose={() => {
        close();
        setData(undefined);
      }}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          {...formProps}
          onFinish={(e) => {
            formProps.onFinish?.(e);
            formProps.form.resetFields();
          }}
          initialValues={dataImport || { analytic_events }}
        >
          <GeneralBodyForm form={formProps.form} />
        </Form>
      )}
    </Drawer>
  );
};
