import { ChartDataTypeEnum } from "@src/hooks/ab-testing/use-chart-layout";
import { UserDistributionTabsKeyEnum } from "@src/pages/ab-test-explore/enum";

export type MoreActionType = "more_actions" | "export_csv" | "view_meta" | "view_json" | "view_query";

type ExpectedTypeEnum = "string" | "number" | "boolean" | "expected_object" | "expected_array";

const eventTrackingTemplate = (eventName: string, eventParams: Record<string, unknown>, automate?: boolean) => ({
  event: {
    name: eventName,
    params: automate
      ? {
          ...eventParams,
          automate,
        }
      : eventParams,
  },
});

class ValidateEventTrackingPayload {
  private validate(values: Record<string, unknown>, expectedValues?: Record<string, ExpectedTypeEnum>) {
    if (!expectedValues) {
      return values;
    }

    const result: Record<string, unknown> = {};
    Object.keys(expectedValues).forEach((valueKey) => {
      if (values[valueKey] === undefined) {
        throw new Error(`Missing required parameter: ${valueKey}`);
      }
      if (typeof values[valueKey] === "object") {
        if (
          Array.isArray(values[valueKey]) &&
          values[valueKey] !== null &&
          expectedValues[valueKey] !== "expected_array"
        ) {
          throw new Error(`Invalid parameter type: ${valueKey}, expected object`);
        }
        if (
          !Array.isArray(values[valueKey]) &&
          values[valueKey] !== null &&
          expectedValues[valueKey] !== "expected_object"
        ) {
          throw new Error(`Invalid parameter type: ${valueKey}, expected array`);
        }
      }
      if (typeof values[valueKey] !== "object" && typeof values[valueKey] !== expectedValues[valueKey]) {
        throw new Error(`Invalid parameter type: ${valueKey}, expected ${expectedValues[valueKey]}`);
      }

      result[valueKey] = values[valueKey];
    });

    return result;
  }
  generalInfoFieldUpdated(
    params: {
      name: "product" | "aba_id" | "platform" | "variants" | "cohort_date" | "day_diff";
      value: string | number | string[] | null;
    },
    expectedParams?: {
      value: ExpectedTypeEnum;
    },
    automate?: boolean,
  ) {
    return eventTrackingTemplate(
      "field_update",
      {
        page: "ab_explore",
        page_section: "general_info",
        field: {
          name: params.name,
          ...this.validate(
            {
              value: params.value,
            },
            expectedParams,
          ),
        },
      },
      automate,
    );
  }
  extraFieldFilter(
    eventName: "button_click" | "modal_open" | "modal_close" | "field_update",
    eventParams: Record<string, unknown>,
    automate?: boolean,
  ) {
    return eventTrackingTemplate(
      eventName,
      {
        page: "ab_explore",
        page_section: "extra_filters",
        ...eventParams,
      },
      automate,
    );
  }
  applyFilter(eventParams: Record<string, unknown>, automate?: boolean) {
    return eventTrackingTemplate(
      "button_click",
      {
        page: "ab_explore",
        page_section: "general_info",
        button_name: "apply",
        ...eventParams,
      },
      automate,
    );
  }
  userCountCardStatus(eventName: "card_expand" | "card_collapse", automate?: boolean) {
    return eventTrackingTemplate(
      eventName,
      {
        page: "ab_explore",
        page_section: "user_chart",
        card_name: "user_overview",
      },
      automate,
    );
  }
  chartSelect(chartName: UserDistributionTabsKeyEnum, automate?: boolean) {
    return eventTrackingTemplate(
      "chart_select",
      {
        page: "ab_explore",
        page_section: "user_chart",
        chart_name: chartName,
      },
      automate,
    );
  }
  chartTypeSelect(chartName: string, chartType: ChartDataTypeEnum, automate?: boolean) {
    return eventTrackingTemplate(
      "type_select",
      {
        page: "ab_explore",
        page_section: "user_chart",
        chart_name: chartName,
        chart_type: chartType,
      },
      automate,
    );
  }
  chartModeSelect(chartName: string, chartMode: "chart" | "raw_data", automate?: boolean) {
    return eventTrackingTemplate(
      "mode_select",
      {
        page: "ab_explore",
        page_section: "user_chart",
        chart_name: chartName,
        chart_mode: chartMode,
      },
      automate,
    );
  }
  chartButtonActionClick(chartName: string, buttonName: MoreActionType, automate?: boolean) {
    return eventTrackingTemplate(
      "more_action_click",
      {
        page: "ab_explore",
        page_section: "user_chart",
        button_name: buttonName,
        chart_name: chartName,
      },
      automate,
    );
  }
  baseMetricFieldUpdated(field: "base_variant" | "time_range", fieldValue: string | string[], automate?: boolean) {
    return eventTrackingTemplate(
      "metric_field_update",
      {
        page: "ab_explore",
        page_section: "metric_explore",
        field: field,
        field_value: fieldValue,
      },
      automate,
    );
  }
  metricTabButtonClick(buttonName: "add" | "duplicate" | "delete", metricName: string, automate?: boolean) {
    return eventTrackingTemplate(
      "metric_button_click",
      {
        page: "ab_explore",
        page_section: "metric_explore",
        button_name: buttonName,
        metric_name: metricName,
      },
      automate,
    );
  }
  metricItemFieldUpdated(
    metricName: string,
    field: "model" | "measure" | "significance_level" | "breakdown" | "filter",
    fieldValue: unknown,
    automate?: boolean,
  ) {
    return eventTrackingTemplate(
      "metric_item_field_update",
      {
        page: "ab_explore",
        page_section: "metric_explore",
        metricName: metricName,
        field: field,
        field_value: fieldValue,
      },
      automate,
    );
  }
  metricItemButtonClick(
    metricName: string,
    buttonName: "open_filter" | "open_breakdown" | "apply",
    otherParams?: Record<string, unknown>,
    automate?: boolean,
  ) {
    return eventTrackingTemplate(
      "metric_item_button_click",
      {
        page: "ab_explore",
        page_section: "metric_explore",
        metricName: metricName,
        button_name: buttonName,
        ...otherParams,
      },
      automate,
    );
  }
  metricReportChartModeSelect(metricName: string, mode: string, automate?: boolean) {
    return eventTrackingTemplate(
      "metric_report_mode_select",
      {
        page: "ab_explore",
        page_section: "metric_explore",
        card_name: "report",
        metricName: metricName,
        mode,
      },
      automate,
    );
  }
  metricReportActionClick(metricName: string, button_name: MoreActionType, automate?: boolean) {
    return eventTrackingTemplate(
      "metric_report_action_click",
      {
        page: "ab_explore",
        page_section: "metric_explore",
        card_name: "report",
        metricName: metricName,
        button_name,
      },
      automate,
    );
  }
  metricReportCardStatus(eventName: "card_expand" | "card_collapse", metric_name: string, automate?: boolean) {
    return eventTrackingTemplate(
      "metric_report_" + eventName,
      {
        page: "ab_explore",
        page_section: "metric_explore",
        card_name: "report",
        metric_name,
      },
      automate,
    );
  }
}

const validateEventTrackingPayload = new ValidateEventTrackingPayload();
export const trackEventPayload = {
  generalInfoFieldUpdated: validateEventTrackingPayload.generalInfoFieldUpdated.bind(validateEventTrackingPayload),
  extraFieldFilter: validateEventTrackingPayload.extraFieldFilter,
  applyFilter: validateEventTrackingPayload.applyFilter,
  userCountCardStatus: validateEventTrackingPayload.userCountCardStatus,
  chartSelect: validateEventTrackingPayload.chartSelect,
  chartTypeSelect: validateEventTrackingPayload.chartTypeSelect,
  chartModeSelect: validateEventTrackingPayload.chartModeSelect,
  chartButtonActionClick: validateEventTrackingPayload.chartButtonActionClick,
  baseMetricFieldUpdated: validateEventTrackingPayload.baseMetricFieldUpdated,
  metricTabButtonClick: validateEventTrackingPayload.metricTabButtonClick,
  metricItemFieldUpdated: validateEventTrackingPayload.metricItemFieldUpdated,
  metricItemButtonClick: validateEventTrackingPayload.metricItemButtonClick,
  metricReportChartModeSelect: validateEventTrackingPayload.metricReportChartModeSelect,
  metricReportActionClick: validateEventTrackingPayload.metricReportActionClick,
  metricReportCardStatus: validateEventTrackingPayload.metricReportCardStatus,
};
